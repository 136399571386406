
const NoPage = () => {
    return (
    <>


<div id="404container" class="mb-6">

<h1>404</h1>

        
</div>


    </>
    )
  };
  
  export default NoPage;