import {React, StrictMode} from 'react';
import ReactDOM from 'react-dom/client';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./layouts/Layout";
import Home from "./pages/Home";
import NoPage from "./pages/NoPage";
import Collections from "./pages/Collections"
import CafeCulture from "./pages/CafeCulture"
import Tech from "./pages/Tech"
import DrFlorg from "./pages/DrFlorg"
import Thanksgiving from './pages/Thanksgiving';
import Faith from './pages/Faith';

export default function App() {

  return (
    <BrowserRouter>
      <Routes>
          <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/collections" element={<Collections />} />
          <Route path="/collections/cafe-culture" element={<CafeCulture />} />
          <Route path="/collections/tech" element={<Tech />} />
          <Route path="/collections/dr-florg" element={<DrFlorg />} />
          <Route path="/collections/thanksgiving" element={<Thanksgiving />} />
          <Route path="/collections/faith" element={<Faith />} />


          <Route path="*" element={<NoPage />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

const root = ReactDOM.createRoot(document.getElementById('root'));
const helmetContext = {};

root.render(
  <StrictMode>
    <HelmetProvider context={helmetContext}>
      <App />
    </HelmetProvider>
  </StrictMode>,
  document.getElementById('root')
);
