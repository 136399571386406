//import { Link } from "react-router-dom";
import { Seo } from '../components/Seo';
import "./Collections.css";

// https://www.pluralsight.com/resources/blog/guides/load-and-render-json-data-into-react-components
import { collectionsData } from '../data/collections'

const Home = () => {

    // get all the categories
    let collection_descr = "";
    collectionsData.map((data, key) => (
        collection_descr = collection_descr + data.title + "; "
    ));


    return (
    <>

      <Seo
        title="Disrupt Wardrobe (c) 2024"
        description="change :: disrupt :: life"
        type="webapp"
      />

    <div class="container px-4 py-5" id="hanging-icons">
    <h2 class="pb-2 border-bottom">Collections</h2>


    <div class="row g-3 py-5 px-3 row-cols-1 row-cols-lg-3">


    {collectionsData.map((data, key) => {
                return (
                    <>
                    {/* begin collection */}


                    <div class="p-1 m-1 col collection_tile">
                    <div class="collection_img_container">  
                       <img class="collection_img" src={`${data.img}`} alt=""/>
                    </div>
                    <h4 class="fs-2 text-body-emphasis">{data.title}</h4>
                        <div>{data.description}</div>
                        <a href={`/collections/${data.slug}`} class="btn btn-sm btn-primary my-2">
                            View Collection
                        </a>
                    </div>

                    </>
                )
            })}



    </div>
  </div>





    </>
    )
  };
  
  export default Home;