export const designsData = [
    {
      type: "DESIGN",
      slug: "DWCCMWL",  
      date: "23 July 2024",
      collection: "Cafe Culture",
      title: "Funny Coffee My Whole Life Is Tired",
      description: "Are you thinking of what to give to a loved one who loves caffeine (more than you?) during Christmas or for a Birthday? This design will help relate to the craziness of life, and the requirement of coffee to deal with it. Perfect for barista, people who love cup of coffee, and people who love spending their time in coffee shops. Great fit for caffeinated people, coffee lover, and baristas in the coffee shop.",
      images: [
        {uri:"/img/cafe-culture/DWCCMWL/premium-t-shirt.jpg", alt:"Premium T-Shirt"},
        {uri:"/img/cafe-culture/DWCCMWL/premium-t-shirt2.jpg", alt:"Premium T-Shirt"},
        {uri:"/img/cafe-culture/DWCCMWL/mouse-pad.jpg", alt:"Mouse Pad"},
        {uri:"/img/cafe-culture/DWCCMWL/mouse-pad2.jpg", alt:"Mouse Pad"},
      ],
      links: [
        {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
        {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
      ]
    },



              
]