import { Outlet, Link } from "react-router-dom";
import './Layout.css';


const Layout = () => {

  const year = new Date().getFullYear();

  return (
    <>
    <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/css/bootstrap.min.css" rel="stylesheet" integrity="sha384-QWTKZyjpPEjISv5WaRU9OFeRpok6YctnYmDr5pNlyT2bRjXh0JMhjY6hW+ALEwIH" crossorigin="anonymous"></link>

    <svg xmlns="http://www.w3.org/2000/svg" class="d-none">
      <symbol id="aperture" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" viewBox="0 0 24 24">
        <circle cx="12" cy="12" r="10"/>
        <path d="M14.31 8l5.74 9.94M9.69 8h11.48M7.38 12l5.74-9.94M9.69 16L3.95 6.06M14.31 16H2.83m13.79-4l-5.74 9.94"/>
      </symbol>
      <symbol id="cart" viewBox="0 0 16 16">
        <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .49.598l-1 5a.5.5 0 0 1-.465.401l-9.397.472L4.415 11H13a.5.5 0 0 1 0 1H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l.84 4.479 9.144-.459L13.89 4H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z"/>
      </symbol>
      <symbol id="chevron-right" viewBox="0 0 16 16">
        <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
      </symbol>
    </svg>

    <nav class="navbar navbar-expand-md bg-dark sticky-top border-bottom" data-bs-theme="dark">
      <div class="container">
        <a class="navbar-brand d-md-none" href="/">
          <svg class="bi" width="24" height="24"><use href="#aperture"/></svg>
          Aperture
        </a>
        <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvas" aria-controls="offcanvas" aria-label="Toggle navigation">
          <span class="navbar-toggler-icon"></span>
        </button>
        <div class="offcanvas offcanvas-end" tabindex="-1" id="offcanvas" aria-labelledby="offcanvasLabel">
          <div class="offcanvas-header">
            <h5 class="offcanvas-title" id="offcanvasLabel">Aperture</h5>
            <button type="button" class="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
            
          </div>
          <div class="offcanvas-body">
            <ul class="navbar-nav flex-grow-1 justify-content-between">
              <li class="nav-item"><a class="nav-link" href="/">
                <img src="/img/disrupt-logo-black.png" alt="Disrupt Wardrobe Logo" width="200"></img>
              </a></li>
              <li class="nav-item"><a class="nav-link" href="/collections">Collections</a></li>
              <li class="nav-item"><a class="nav-link" href="/about">About Us</a></li>


              <li class="nav-item dropdown">
                <a class="nav-link dropdown-toggle" href="/" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                  <svg class="bi" width="24" height="24"><use href="#cart"/></svg>&nbsp;
                  Where To Buy
                </a>
                <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <li><a class="dropdown-item" href="https://www.redbubble.com/people/aschwabe/shop">Red Bubble</a></li>
                  <li><a class="dropdown-item" href="https://www.amazon.com/s?rh=n%3A7141123011%2Cp_4%3ADisruptWardrobe">Amazon US</a></li>
                  {/* <li><a class="dropdown-item" href="https://www.amazon.com/s?rh=n%3A7141123011%2Cp_4%3ADisruptWardrobe&ref=bl_sl_s_ap_web_7141123011">Amazon UK</a></li>
                  <li><hr class="dropdown-divider"/></li>
                  <li><a class="dropdown-item" href="#">Something else here</a></li> */}
                </ul>
              </li>

            </ul>
          </div>
        </div>
      </div>
    </nav>

    <Outlet />

    <footer class="container py-5">
      <div class="row">
        <div class="col-12 col-md">
        <img src="/img/disrupt-logo-on-white.png" alt="Disrupt Wardrobe Logo" width="250"></img>
          <small class="d-block mb-3 text-body-secondary">Disrup Wardrobe &copy; {`${year}`} <br/>A service of Schwabe Enterprises, LLC</small>
        </div>
        <div class="col-6 col-md">
          <h5>Collections</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-secondary text-decoration-none" href="/collections/cafe-culture">Cafe Culture</a></li>
            <li><a class="link-secondary text-decoration-none" href="/collections/dr-florg">Dr. Florg</a></li>
            <li><a class="link-secondary text-decoration-none" href="/collections/faith">Faith</a></li>
            <li><a class="link-secondary text-decoration-none" href="/collections/tech">Tech</a></li>
            <li><a class="link-secondary text-decoration-none" href="/collections/thanksgiving">Thanksgiving</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>About</h5>
          <ul class="list-unstyled text-small">
            <li><a class="link-secondary text-decoration-none" href="/about">Team</a></li>
            <li><a class="link-secondary text-decoration-none" href="/privacy">Privacy</a></li>
            <li><a class="link-secondary text-decoration-none" href="/terms">Terms</a></li>
          </ul>
        </div>
        <div class="col-6 col-md">
          <h5>Where To Buy</h5>
          <ul class="list-unstyled text-small">
            <li><Link class="link-secondary text-decoration-none" to="https://www.redbubble.com/people/aschwabe/shop">RedBubble</Link></li>
            <li><a class="link-secondary text-decoration-none" href="https://www.amazon.com/s?rh=n%3A7141123011%2Cp_4%3ADisruptWardrobe">Amazon USA</a></li>
            {/* <li><a class="link-secondary text-decoration-none" href="#">Amazon UK</a></li>
            <li><a class="link-secondary text-decoration-none" href="#">???</a></li> */}
          </ul>
        </div>
      </div>
    </footer> 

    <script src="https://ajax.googleapis.com/ajax/libs/jquery/3.7.1/jquery.min.js"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.bundle.min.js" integrity="sha384-YvpcrYf0tY3lHB60NNkmXc5s9fDVZLESaAA55NDzOxhy9GkcIdslK1eN7N6jIeHz" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/@popperjs/core@2.11.8/dist/umd/popper.min.js" integrity="sha384-I7E8VVD/ismYTF4hNIPjVp/Zjvgyol6VFvRkX/vR+Vc4jQkC+hVqc2pM8ODewa9r" crossorigin="anonymous"></script>
    <script src="https://cdn.jsdelivr.net/npm/bootstrap@5.3.3/dist/js/bootstrap.min.js" integrity="sha384-0pUGZvbkm6XF6gxjEnlmuGrJXVbNuzT9qBBavbLwCsOGabYfZo0T0to5eqruptLy" crossorigin="anonymous"></script>  

    </>
  )
};

export default Layout;