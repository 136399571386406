export const collectionsData = [
    {
      type: "COLLECTION",
      slug: "cafe-culture",  
      date: "23 July 2024",
      title: "Cafe Culture",
      description: "Coffee == Life.  Make a robusta statment about the urgency of Caffeine (1,3,7-trimethylxanthine)",
      img: "/img/cafe-culture/dw_whole_life_tired_tote_bag.jpg",
      links: [
        {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
        {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
        {"name": "Amazon UK", "uri": "blah"}
      ]
    },
    {
        type: "COLLECTION",
        slug: "dr-florg",  
        date: "23 July 2024",
        title: "Dr. Florg",
        description: "The original Dr. Florg.  Good stories, games and times.",
        img: "/img/dr-florg/dw_dr_florg_spiral_notebook.jpg",
        links: [
          {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
          {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
          {"name": "Amazon UK", "uri": "blah"}
        ]
    },
  
    {
        type: "COLLECTION",
        slug: "faith",  
        date: "23 July 2024",
        title: "Faith",
        description: "No pickup lines here.  Just lines to pick you up.",
        img: "/img/faith/dw_lion_judah_scarf.jpg",
        links: [
          {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
          {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
          {"name": "Amazon UK", "uri": "blah"}
        ]
      },
      {
        type: "COLLECTION",
        slug: "tech",  
        date: "23 July 2024",
        title: "Tech",
        description: "Dope threads and designs to help you make your statement.",
        img: "/img/tech/dw_eat_sleep_automate_black.jpg",
        links: [
          {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
          {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
          {"name": "Amazon UK", "uri": "blah"}
        ]
      },
      {
        type: "COLLECTION",
        slug: "thanksgiving",  
        date: "23 July 2024",
        title: "Thanksgiving",
        description: "Must. eat. more. turkey.  Bring out the inner Tryptophan.",
        img: "/img/thanksgiving/dw_thanksgiving_bring_it_on_turkey_apron.jpg",
        links: [
          {"name": "RedBubble", "uri": "https://www.youtube.com/watch?v=6wlYunvuYFc"},
          {"name": "Amazon USA", "uri": "https://podcasters.spotify.com/pod/show/techtonic-lounge/episodes/E11-Demystifying-A-I--for-Business-People-with"},
          {"name": "Amazon UK", "uri": "blah"}
        ]
      },
    

]